import axios from "axios";
import { UltravoxSession } from 'ultravox-client';
import { ListUl } from "@styled-icons/boxicons-regular";
import {
    PhoneCall,
    Edit,
    UserPlus,
    UserX,
    Group,
    InfoCircle,
    PhoneOff,
} from "@styled-icons/boxicons-solid";
import { observer } from "mobx-react-lite";
import { Link, useHistory } from "react-router-dom";
import { UserPermission, API } from "revolt.js";

import styles from "./UserProfile.module.scss";
import { Localizer, Text } from "preact-i18n";
import { useEffect, useLayoutEffect, useState } from "preact/hooks";

import {
    Button,
    Category,
    Error,
    IconButton,
    Modal,
    Preloader,
} from "@revoltchat/ui";

import { noop } from "../../../../lib/js";

import ChannelIcon from "../../../../components/common/ChannelIcon";
import ServerIcon from "../../../../components/common/ServerIcon";
import Tooltip from "../../../../components/common/Tooltip";
import UserBadges from "../../../../components/common/user/UserBadges";
import UserIcon from "../../../../components/common/user/UserIcon";
import { Username } from "../../../../components/common/user/UserShort";
import UserStatus from "../../../../components/common/user/UserStatus";
import Markdown from "../../../../components/markdown/Markdown";
import { useSession } from "../../../../controllers/client/ClientController";
import { modalController } from "../../../../controllers/modals/ModalController";
import { ModalProps } from "../../types";

export const VoiceCall = observer(
    ({
        user_id,
        isPlaceholder,
        placeholderProfile,
        ...props
    }: ModalProps<"voice_call">) => {
        const [profile, setProfile] = useState<
            undefined | null | API.UserProfile
        >(undefined);
        const [mutual, setMutual] = useState<
            undefined | null | API.MutualResponse
        >(undefined);
        const [isPublicBot, setIsPublicBot] = useState<
            undefined | null | boolean
        >();

        const [yuerAgent, setYuerAgent] = useState<undefined | null | any>()
        const [voiceCallStatus, setVoiceCallStatus] = useState<undefined | null | string>()
        const [voiceMessages, setVoiceMessages] = useState<undefined | null | []>()
        const [voiceSession, setVoiceSession] = useState<undefined | null | UltravoxSession>()
            
        const history = useHistory();
        const session = useSession()!;
        const client = session.client!;
        const [tab, setTab] = useState("profile");

        useEffect(() => {
            console.log("init voice session")
            const voiceSession = new UltravoxSession();
            voiceSession.addEventListener('status', (event: any) => {
                console.log('Session status changed: ', event, voiceSession.status);
                setVoiceCallStatus(voiceSession.status)
            });
    
            voiceSession.addEventListener('transcripts', (event: any) => {
                console.log('Transcripts updated: ', voiceSession.transcripts);
                const list:any = []
                voiceSession.transcripts?.map(m => {
                    const msg = {...m}
                    list.push(msg)
                });
                console.log('message list', list)
                setVoiceMessages(list)
            });
            setVoiceSession(voiceSession)
        }, [])

        useEffect(() => {
            axios
            .create(client.api.config)
            .post("/agents/api/agent/resolve/by/revolt", {id: user_id})
            .then(({ data }) => {
                console.log('resolve agent', data)
                setYuerAgent(data.data)
            });
        }, []);
        

        const user = client.users.get(user_id);
        if (!user) {
            if (props.onClose) useEffect(props.onClose, []);
            return null;
        }

        const users = mutual?.users.map((id) => client.users.get(id));

        const mutualGroups = [...client.channels.values()].filter(
            (channel) =>
                channel?.channel_type === "Group" &&
                channel.recipient_ids!.includes(user_id),
        );

        const mutualServers = mutual?.servers.map((id) =>
            client.servers.get(id),
        );

        useLayoutEffect(() => {
            if (!user_id) return;
            if (typeof profile !== "undefined") setProfile(undefined);
            if (typeof mutual !== "undefined") setMutual(undefined);
            if (typeof isPublicBot !== "undefined") setIsPublicBot(undefined);
            // eslint-disable-next-line
        }, [user_id]);

        useEffect(() => {
            if (isPlaceholder) {
                setProfile(placeholderProfile);
            }
        }, [isPlaceholder, placeholderProfile]);

        useEffect(() => {
            if (isPlaceholder) return;
            if (session.state === "Online" && typeof mutual === "undefined") {
                setMutual(null);
                user.fetchMutual().then(setMutual);
            }
        }, [mutual, session.state, isPlaceholder, user]);

        useEffect(() => {
            if (isPlaceholder) return;
            if (session.state === "Online" && typeof profile === "undefined") {
                setProfile(null);

                if (user.permission & UserPermission.ViewProfile) {
                    user.fetchProfile().then(setProfile).catch(noop);
                }
            }
        }, [profile, session.state, isPlaceholder, user]);

        useEffect(() => {
            if (
                session.state === "Online" &&
                user.bot &&
                typeof isPublicBot === "undefined"
            ) {
                setIsPublicBot(null);
                client.bots
                    .fetchPublic(user._id)
                    .then(() => setIsPublicBot(true))
                    .catch(noop);
            }
        }, [isPublicBot, session.state, user, client.bots]);

        const backgroundURL =
            profile &&
            client.generateFileURL(
                profile.background as any,
                { width: 1000 },
                true,
            );

        const badges = user.badges ?? 0;
        const flags = user.flags ?? 0;

        const createCall = () => {
            axios
            .create(client.api.config)
            .post("/agents//api/agent/voice/call/create", {id: user_id})
            .then(({ data }) => {
               console.log('create call response', data)
               voiceSession.joinCall(data.data.join_url)
               setVoiceMessages([])
            });
        }

        const leaveCall = () => {
            console.log('voiceSession leaveCall status before', voiceSession.status)
            voiceSession.leaveCall().then(() => {
                console.log('voiceSession leaveCall status after', voiceSession.status)
            })
        }


        const children = (
            <>
           
                <div
                    className={styles.header}
                    data-force={profile?.background ? "light" : undefined}
                    style={{
                        backgroundImage:
                            backgroundURL &&
                            `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('${backgroundURL}')`,
                        paddingBottom: "1px",
                    }}>
                      
                    <div className={styles.profile}>
                        <UserIcon
                            size={80}
                            target={user}
                            status
                            animate
                            hover={typeof user.avatar !== "undefined"}
                            onClick={() =>
                                user.avatar &&
                                modalController.push({
                                    type: "image_viewer",
                                    attachment: user.avatar,
                                })
                            }
                        />
                        <div className={styles.details}>
                            <div className={styles.usernameDetail}>
                                <span
                                    className={styles.displayname}
                                    onClick={() =>
                                        modalController.writeText(user.username)
                                    }>
                                    {user.display_name ?? user.username}
                                </span>
                                
                            </div>
                            {user.status?.text && (
                                <span className={styles.status}>
                                    <UserStatus user={user} tooltip />
                                </span>
                            )}
                        </div>
                       
                       {yuerAgent?.agentExists && yuerAgent?.agentEnableVoiceCall?  <>
                        <span>
                        {voiceCallStatus == undefined || voiceCallStatus == 'disconnected'? 
                        <>
                        <IconButton
                            onClick={createCall}>
                            <PhoneCall size={30} />
                        </IconButton>
                        </> 
                        : 
                        <>
                        <IconButton
                            style={{color: 'red'}}
                            onClick={leaveCall}>
                            <PhoneOff size={30} />
                        </IconButton>
                        </>
                        }
                       </span> 
                       </> : null}
                    </div>
                </div>
                <div className={styles.content}>
                        {yuerAgent?.agentExists && yuerAgent?.agentEnableVoiceCall? 
                        <>
                        {voiceMessages?.filter(m => 'agent' == m.speaker).map(m => {
                            return (
                                <span><span style={{fontWeight: 'bold'}}>{user.display_name ?? user.username}: </span><span>{m.text}</span></span>
                            )
                        })}
                        </>
                        : 
                        <><div 
                        style={{
                           textAlign: 'center',
                           marginTop: '50px'
                        }}>
                          未开通呼叫能力  
                        </div></>}
                </div>
            </>
        );

        if (isPlaceholder) return <div>{children}</div>;

        return (
            <Modal
                {...props}
                nonDismissable={isPlaceholder}
                transparent
                maxWidth="560px">
                {children}
            </Modal>
        );
    },
);
